export default {
  data() {
    return {
      timerStatus: '',
      updateTime: null,
      status: '',
    };
  },
  computed: {},
  beforeDestroy() {
    this.resetTimers();
  },
  methods: {
    updateStatus(module, needUpdateList = false) {
      if (!this.updateTime) this.updateTime = Date.now();
      this.timerStatus = setTimeout(() => {
        if (needUpdateList) {
          this.$store.dispatch(module, { silent: true, status: true });
        }
        this.$store.dispatch(module, { silent: true, status: true }).then(data => {
          const curTime = Date.now();
          const checkStatus =
            data.elem && data.elem.find(x => x.status !== '2')
              ? data.elem.find(x => x.status !== '2').status
              : null;
          // console.log(checkStatus);
          const TIMEOUT = 1000 * 60 * 5;
          const needCheck = curTime - this.updateTime < TIMEOUT;
          if (checkStatus === '5' && needCheck) {
            this.status = 'updating';
            this.updateStatus(module, needUpdateList);
          } else {
            this.status = 'success';
            clearTimeout(this.timerStatus);
          }
        });
      }, 1500);
      return this.status;
    },
    resetTimers() {
      this.updateTime = null;
      if (this.timerStatus) clearTimeout(this.timerStatus);
    },
  },
};
